.titre {
    padding: 0.5rem 1rem;
    background-image: linear-gradient(-135deg,#1400c8,#b900b4,#f50000);
    color: #fff;
    font-weight: 900;
}

.w110 {
    width: 100px;
}
