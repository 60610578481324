/* VARIABLES */
@import "variables";

/* BOOTSTRAP */
@import "~bootstrap/scss/bootstrap";

/* PLUGINS */
@import "plugins/divers.scss";
@import "plugins/fontsize.css";
@import "plugins/img_gal.scss";
@import "plugins/svg-icons.scss";

/* FONTS */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,400italic,900&display=swap");

/* COTI STYLE */

/* MENU */
#cotiNav {
  background-color: #0003;
  box-shadow: none;
  transition: all 500ms;
  .navbar-brand {
    margin-left: .5rem;
    margin-right: .5rem;
  }
  .nav-link {
    padding: .5rem .5rem .25rem;
    color: #fafafa;
    &:hover {
      color: #fff;
    }
  }
  .dropdown-item:hover {
    background-color: $info;
    color: #fff;
  }
}

.stuck #cotiNav {
  background: #03a9f4;
  box-shadow: inset 0 0 24px #0004;
}

@media only screen and (min-width: 768px) {
  .navbar-brand {
    margin-left: 0;
  }
}

/* CAROUSEL */
.carousel-caption {
  a, p {
    text-shadow: 0 2px 3px #000;
  }
}
.carousel-control-prev {
  justify-content: flex-start;
  padding-left: 3%;
}
.carousel-control-next {
  justify-content: flex-end;
  padding-right: 3%;
}
.carousel-item {
  max-height: 400px;
}

/* FORM */
.input-group > .input-group-append > .btn {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}
.btn-default {
  color: #fafafa;
  background-color: #2bbbad!important;
  &:hover {
    color: #fff;
  }
}

/* CARD */
.card {
  border-radius: 0;
  transition: all 300ms;
  &:hover {
    box-shadow: 0 3px 9px #999;
  }
  .top-img-art {
    width: 100%;
    min-height: 100px;
    object-fit: cover;
    overflow: hidden;
  }
}

.card-titre {
  margin: 0 -1px 0 0;
  padding: .5rem;
  background: linear-gradient(45deg,#fd9d52,#dd45d3 50%,#b13cff);
  color: #fff;
  font-size: 1.4rem;
  font-weight: 900;
}

/* MAIN */
.sous-titre br {
  content: "A" !important;
  display: block !important;
  margin-bottom: .5rem !important;
}

/* IMAGES */
.coti-grid-img {
  width: 100%;
  height: 18vh;
  object-fit: cover;
  transition: 400ms;
  &:hover {
    transform: scale(1.05);
  }
}

/* FOOTER */
footer {
  margin-top: 1rem;
  background: linear-gradient(135deg, #1600cc, #6500a5, #00499c);
  box-shadow: inset 0 2rem 6rem #000;
  font-size: .9rem;
  a {
    color: $light;
    &:hover {
      color: $white;
    }
  }
  .footer-bottom {
    background: #0003;
  }
  @include media-breakpoint-up(sm) {
    box-shadow: inset 0 4rem 12rem #000;
  }
}

/* GO TOP */
.scrollToTopBtn {
  position: fixed;
  bottom: 2rem;
  right: 0;
  height: 64px;
  width: 64px;
  background-image: url("/images/gotop.png");
  &:hover {
    background-image: url("/images/gotop-hover.png");
  }
  transform: translateX(100px);
  transition: all 500ms;
  z-index: 1050;
}

.showScrollBtn {
  transform: translateX(0);
}
