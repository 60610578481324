/* ACTIVATION OPTIONS */
$enable-negative-margins: true;
$enable-responsive-font-sizes: true;

/* GRILLE */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

/* NAVBAR */
$navbar-brand-padding-y: 0;

/* COLOR */
$min-contrast-ratio: 3.0;
$dark: #304a74;
$primary: #007bff;

/* FONT */
$font-family-base: "Roboto";
$font-weight-normal: 300;
$line-height-base: 1.6;
$headings-font-weight: 300;

/* LIENS */
$link-decoration: none;

/* BUTTON */
$btn-border-width: 0;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$btn-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
$btn-font-weight: 400;
