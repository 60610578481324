.img-columns {
    @include row-cols(1);
    img {
        width: 100%;
        object-fit: cover;
        max-height: 175px;
    }

    @media(min-width: 400px) {
        @include row-cols(2);
        img {
            max-height: 156px;
        }
    }

    @media(min-width: 480px) {
        img {
            max-height: 156px;
        }
    }

    @media (min-width: 576px) {
        @include row-cols(3);
        img {
            max-height: 120px;
        }
    }
  
    @media (min-width: 660px) {
        img {
            max-height: 136px;
        }
    }

    @media (min-width: 768px) {
        @include row-cols(4);
        img {
            max-height: 121px;
        }
    }
  
    @media (min-width: 992px) {
        @include row-cols(5);
        img {
            max-height: 123px;
        }
    }
  
    @media (min-width: 1200px) {
        @include row-cols(6);
        img {
            max-height: 121px;
        }
    }
}
